<template>
  <div class="video-form-page">
    <form-layout>
      <template slot="back-button">
        <el-button name="back-btn" type="info" :plain="true" @click="cancelHandler">{{ $tf('back') }}</el-button>
      </template>

      <span slot="title">{{ title }}</span>

      <div slot="left-tabs">
        <common-tabs v-model="tab" :items="tabs" vertical class="header-tabs" :is-create-mode="create"></common-tabs>
      </div>

      <div slot="left-controls">
        <controls
          :state="state"
          :item="item"
          :create="create"
          :loading="loading"
          :multi-edit="multiEdit"
          :save-disabled="saveDisabled"
          :changes-count="changesCount"
          @save="savePipeline"
          @delete="deleteHandler"
          @delete-file="deleteFileHandler"
          @reset="setItemDefaultParameters"
        ></controls>
      </div>

      <div slot="body">
        <video-info-form
          :item="item"
          :loading="loading"
          v-if="tab === VideoTabNames.Info"
          :disabled="disabled"
          @save="savePipeline"
          @cancelUpload="cancelUpload"
          @getForm="getForm"
        />

        <video-parameters-form
          :item="item"
          :multi-edit="multiEdit"
          :default-parameters="defaultParameters"
          v-if="tab === VideoTabNames.Parameters"
          :disabled="disabled"
        />

        <camera-advanced-form
          :item="item"
          :default-parameters="defaultParameters"
          :multi-edit="multiEdit"
          :loading="loading"
          :mode="'video'"
          :disabled="disableEdit || disabled"
          v-if="tab === VideoTabNames.Advanced"
        ></camera-advanced-form>

        <camera-analytics-form
          :item="item"
          :default-parameters="defaultParameters"
          :multi-edit="multiEdit"
          :disabled="disableEdit || disabled"
          :loading="loading"
          :mode="'video'"
          v-if="tab === VideoTabNames.Analytics"
        >
        </camera-analytics-form>

        <stream-settings-roi-form
          v-if="tab === VideoTabNames.Roi"
          :item="item"
          :loading="loading"
          :disabled="disableEdit || disabled"
          :image="internalFormContent.image"
        />
        <stream-settings-rot-form
          v-if="tab === VideoTabNames.Rot"
          :item="item"
          :loading="loading"
          :disabled="disableEdit || disabled"
          :image="internalFormContent.image"
        />

        <video-processing-form
          ref="videoProccessingForm"
          v-if="tab === VideoTabNames.Processing"
          :item="item"
          :form-content="internalFormContent"
          :disabled="disabled"
          :loading="loading"
          @update="savePipeline"
          @process="startProcessingHandler"
          @stop="stopProcessingHandler"
          @getEpisodes="getEpisodes"
        />
      </div>
    </form-layout>
  </div>
</template>

<script>
import { Watch, Component } from 'vue-property-decorator';
import axios from 'axios';
import qs from 'qs';
import _ from '@/apps/common/lodash';
import FormLayout from '../../common/form.layout';
import VideoInfoForm from './info';
import VideoParametersForm from './parameters';
import VideoProcessingForm from './processing';
import Controls from '../controls';
import CameraAnalyticsForm from '../../cameras/form/analytics';
import CameraAdvancedForm from '../../cameras/form/settings/advanced';
import StreamSettingsRotForm from '../../cameras/form/settings/rot';
import StreamSettingsRoiForm from '../../cameras/form/roi';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';
import PageLayout from '@/components/page/layout';
import { humanVideoEpisodesModule, carVideoEpisodesModule } from '../../../store';

const VideoTabNames = {
  Info: 'info',
  Parameters: 'parameters',
  Advanced: 'advanced',
  Analytics: 'analytics',
  Roi: 'roi',
  Rot: 'rot',
  Processing: 'processing'
};

const CANCELLED_BY_USER = 'Cancelled by user';

@Component({
  name: 'video-form',
  components: {
    StreamSettingsRoiForm,
    StreamSettingsRotForm,
    CameraAdvancedForm,
    CameraAnalyticsForm,
    VideoProcessingForm,
    VideoParametersForm,
    VideoInfoForm,
    FormLayout,
    PageLayout,
    Controls
  }
})
export default class VideoForm extends Component {
  tab = 'info';
  defaultParameters = {};
  loading = false;
  isDestroyed = false;
  item = {};
  originalItem = {};
  VideoTabNames = VideoTabNames;
  internalFormContent = { image: null };
  cancelTokenSource;
  disabled = false;
  hasError = false;
  targetType = 'humans';
  forms = [];

  get title() {
    let r;

    if (this.multiEdit) {
      r = 'multi_edit | video,,1';
    } else if (this.create && this.loading) {
      r = 'upload | video,,1';
    } else if (this.create) {
      r = 'create | video,,1';
    } else {
      r = 'edit | video,,1';
    }

    return this.$tfo(r);
  }

  get tabs() {
    const tabs = [
        { name: 'info', i18n: 'general,,3' },
        { name: 'parameters', i18n: 'parameters' },
        { name: 'advanced', i18n: 'advanced' },
        { name: 'analytics', i18n: 'analytics' },
        { name: 'rot', i18n: 'rot' },
        { name: 'roi', i18n: 'roi' },
        this.item.source_file ? null : { name: 'processing', i18n: 'processing_s' }
      ],
      createTabs = [tabs[0]],
      multiEditTabs = [tabs[1], tabs[2], tabs[3]];
    let r = [];

    if (this.multiEdit) {
      r = multiEditTabs;
    } else if (this.create && !this.item.id) {
      r = createTabs;
    } else {
      r = tabs.filter((v) => !!v);
    }

    return r;
  }

  get hasDetector() {
    return Object.values(this.item.stream_settings?.detectors || {}).some((v) => !!v);
  }

  get disableEdit() {
    return this.item.active;
  }

  get sourceFileName() {
    const file = this.item.source_file;
    return file instanceof File ? file.name : '';
  }

  get create() {
    return !this.multiEdit && !this.$route.params.id;
  }

  get multiEdit() {
    return this.$route.path.indexOf('/videos/multi-edit') > -1;
  }

  get state() {
    return this.$store.state.videos;
  }

  get imageUrl() {
    return this.$store.state.config.server.url + 'videos/' + this.item.id + '/screenshot/';
  }

  get saveDisabled() {
    const hasChanges = this.multiEdit || this.changesCount > 0;
    return !hasChanges || this.$hasNoPermission('ffsecurity.change_videoarchive');
  }

  get episodesModule() {
    return this.targetType === 'cars' ? carVideoEpisodesModule : humanVideoEpisodesModule;
  }

  @Watch('$route.path')
  handleRouteChange(v) {
    if (v.indexOf('/create/') > -1) this.initEmptyWithQuery();
    else {
      this.load();
    }
  }

  @Watch('state.processing.progress', { deep: true })
  handleProgressChange(v) {
    const progress = (v && v[this.item.id]) || undefined;
    if (progress > -1) this.item.progress = progress;
  }

  @Watch('state.processing.health_status', { deep: true })
  handleHealthStatusChange(v) {
    const health_status = v && v[this.item.id];
    if (!health_status) return;
    this.item.health_status = health_status;
  }

  @Watch('state.processing.state', { deep: true })
  handleStateChange(v) {
    const item = v && v[this.item.id];
    if (!item) return;
    this.item = item;
  }

  created() {
    this.initEmptyWithQuery();
    this.load();
    this.loadDefaultParameters();
    this.$store.dispatch(this.$store.state.videos.Action.Get);
    this.$store.dispatch(this.$store.state.groups.Action.Get);
    if (this.multiEdit) this.tab = VideoTabNames.Parameters;
  }

  destroyed() {
    this.isDestroyed = true;
  }

  initEmptyWithQuery() {
    const params = this.$route.params.params,
      paramsObj = qs.parse(params, { arrayLimit: 100 });
    this.item = Object.assign(this.getItemWithEnabledDetectors(this.state.item.empty), paramsObj);
  }

  get changes() {
    const { fields } = this.state.item,
      item = _.pickBy(this.item, (v, k) => fields.includes(k)),
      originalItem = _.pickBy(this.originalItem, (v, k) => fields.includes(k));

    let result = _.differenceOf(item, originalItem);
    return result;
  }

  get changesCount() {
    const { fields } = this.state.item,
      item = _.pickBy(this.item, (v, k) => fields.includes(k)),
      originalItem = _.pickBy(this.originalItem, (v, k) => fields.includes(k));

    let result = _.differenceOf(item, originalItem);
    return Object.keys(result).length;
  }

  get selectedCasePath() {
    return this.$router.historyItems.find(({ name }) => name === 'case')?.path ?? null;
  }

  async load() {
    const { id } = this.$route.params;
    if (id) {
      this.loading = true;
      try {
        const item = await this.$store.dispatch(this.state.Action.Get, { id: decodeURIComponent(id) });
        this.item = item;
        this.originalItem = _.cloneDeep(item);
        this.setVideoEpisodesFilter();
        this.loadImage();
      } catch (e) {
        !this.hasError && this.errorNotify(e);
        this.hasError = true;
      }
      this.loading = false;
    }
  }

  savePipeline() {
    if (this.loading) return;

    Promise.all(this.forms.map((form) => form.validate()))
      .then(() => {
        this.multiEdit ? this.multiEditSaveAndProcess() : this.save();
      })
      .catch((e) => {
        this.errorNotify(this.$tt('error.form.validation'), 1000);
      });
  }

  async multiEditSaveAndProcess() {
    const { ids } = qs.parse(this.$route.params.options),
      itemFromFields = _.pickBy(this.item, (v, k) => this.state.item.multi_fields.includes(k));

    this.loading = true;
    for (let id of ids) {
      let currentItem = await this.executeItemAction(this.state.Action.Get, { id }, { rejectable: true, cancel: true }),
        copiedCurrentStreamSettingsFields = _.pickBy(currentItem.stream_settings, (v, k) => this.state.item.multi_copy_stream_settings_fields.includes(k));
      const changedItem = {
        ...itemFromFields,
        id,
        stream_settings: { ...itemFromFields.stream_settings, ...copiedCurrentStreamSettingsFields }
      };

      for (const key in changedItem.stream_settings.detectors) {
        const copiedFileds = this.state.item.multi_copy_detectors_fields,
          currentDetector = currentItem.stream_settings.detectors[key] || {},
          changedDetector = changedItem.stream_settings.detectors[key],
          hasDetector = !!changedDetector,
          resultFields = _.pickBy(currentDetector, (v, k) => copiedFileds.includes(k));
        if (hasDetector) Object.assign(changedDetector, resultFields);
      }
      let updateResultItem = await this.executeItemAction(this.state.Action.Update, changedItem, { cancel: true });
      if (updateResultItem) await this.executeItemAction(this.state.Action.Process, changedItem);
    }
    this.loading = false;
    this.$router.push({ path: this.selectedCasePath ?? '/videos/filter/limit=1000' });
  }

  async save() {
    let action = this.create ? this.state.Action.Create : this.state.Action.Update,
      file = this.item.source_file,
      updatedItem = this.computeActionArgumentsFromItem();

    try {
      this.loading = true;
      let item = await this.executeItemAction(action, updatedItem, { rejectable: true });
      item.source_file = file;
      item.progress = 0;
      this.item = item;
      this.originalItem = _.cloneDeep(item);
      this.setVideoEpisodesFilter(); // @todor

      if (file) {
        try {
          await this.uploadItem(file, this.item);
          this.afterSave();
          this.successNotify('file | uploaded,,1 | successfully');
        } catch (e) {
          await this.deleteAction();
          if (e.message !== CANCELLED_BY_USER) {
            this.errorNotify(e);
          }
        }
      }

      this.item.source_file = null;
    } catch (e) {
      console.warn('[videos:save] error', e);
    } finally {
      this.loading = false;
    }
  }

  afterSave() {
    const path = '/videos/' + this.item.id + '/';
    if (this.isDestroyed) return;
    this.create ? this.$router.replace({ path }) : this.handleRouteChange(path);
  }

  async executeItemAction(action, updatedItem, options = {}) {
    let { rejectable, successMessage, cancel } = options,
      item,
      error;

    try {
      item = await this.$store.dispatch(action, updatedItem);
      if (!cancel) this.successNotify(successMessage);
    } catch (e) {
      error = e;
      this.errorNotify(e);
    }

    return error && rejectable ? Promise.reject(error) : item;
  }
  getForm(value) {
    this.forms.push(value);
  }

  uploadItem(file, item) {
    return this.$store.dispatch('uploadVideo', { file, item });
  }

  cancelUpload() {
    const uploadingItem = this.state.uploading[this.item.id];
    uploadingItem?.cancel(CANCELLED_BY_USER);
  }

  computeActionArgumentsFromItem() {
    return _.pickBy(this.item, (v, k) => v !== '' && this.state.item.fields.includes(k));
  }

  async deleteFileHandler() {
    await this.executeItemAction(this.state.Action.DeleteFile, this.item);
  }

  async deleteAction() {
    try {
      await this.executeItemAction(this.state.Action.Delete, this.item, { rejectable: true });
      this.item.id = null;
    } catch (e) {}
  }

  async deleteHandler() {
    await this.deleteAction();
    this.$router.backTo({ path: '/videos/filter/limit=1000' });
  }

  loadDefaultParameters() {
    this.$store
      .dispatch(this.$store.state.cameras.Action.GetDefaultParameters, this.item)
      .then((v) => {
        this.defaultParameters = v;
        if (this.create || this.multiEdit) {
          this.setItemDefaultParameters();
        }
      })
      .catch((e) => {
        this.disabled = true;
        !this.hasError && this.errorNotify(e);
        this.hasError = false;
      });
  }

  setItemDefaultParameters() {
    const { stream_settings } = this.getItemWithEnabledDetectors(this.defaultParameters);
    this.item.stream_settings = stream_settings;
  }

  resetHandler(e) {
    this.$store
      .dispatch(this.$store.state.cameras.Action.GetDefaultParameters, this.item)
      .then((v) => {
        this.defaultParameters = v;
        this.setItemDefaultParameters();
      })
      .catch((e) => {
        this.errorNotify(e);
      });
    this.disabled = true;
  }

  loadImage() {
    const url = this.imageUrl,
      headers = { Authorization: 'Token ' + encodeURIComponent(this.$store.state.app.token) };

    return axios({ url, responseType: 'blob', headers })
      .then((v) => {
        if (v.data && v.data.size) this.internalFormContent.image = URL.createObjectURL(v.data);
      })
      .catch((e) => {
        this.errorNotify(e);
      });
  }

  async startProcessingHandler(e) {
    this.$refs.videoProccessingForm.loading = true;
    try {
      await this.$store.dispatch(this.state.Action.Process, this.item);
      this.episodesModule.items = [];
      this.item.progress = 0;
    } catch (e) {
      this.errorNotify(e);
    } finally {
      this.$refs.videoProccessingForm.loading = false;
    }
  }

  async stopProcessingHandler(e) {
    this.$refs.videoProccessingForm.loading = true;
    try {
      await this.$store.dispatch(this.state.Action.Stop, this.item);
      this.item.progress = 0;
    } catch (e) {
      this.errorNotify(e);
    } finally {
      this.$refs.videoProccessingForm.loading = false;
    }
  }

  successNotify(message) {
    this.$notify({ type: 'success', message: this.$tf(message || 'action | success') });
  }

  errorNotify(e) {
    this.$notify({ duration: 0, message: this.$createElement('message-box', { props: { e: e } }) });
  }

  setVideoEpisodesFilter() {
    const filter = this.episodesModule.filter.current;
    Object.assign(filter, { video_archive: this.item.id });
  }

  async getEpisodes(targetType) {
    try {
      this.targetType = targetType;
      await this.setVideoEpisodesFilter();
      await this.episodesModule.get();
      Object.freeze(this.episodesModule.items);
    } catch (e) {
      this.errorNotify(e);
    }
  }

  getItemWithEnabledDetectors(item) {
    const disabledObjects = this.$store.getters.disabledObjects;
    const result = _.cloneDeep(item);
    disabledObjects.forEach((v) => {
      result.stream_settings.detectors[ObjectsTypeSingleForm[v]] = null;
    });
    return result;
  }

  cancelHandler() {
    this.$router.back();
  }
}
</script>
