



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { PlainObject } from '@/components/tables/types';

@Component
export default class Controls extends Vue {
  @Prop({ type: Object, required: true })
  item!: PlainObject;

  @Prop({ type: Boolean, required: true })
  create!: boolean;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  @Prop({ type: Boolean, default: false })
  saveDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  multiEdit!: boolean;

  @Prop({ type: Number, default: 0 })
  changesCount!: number;

  get savePipelineButtonTitle() {
    return this.$tf(this.multiEdit ? 'process' : 'save');
  }

  handleMoreCommand(command: string) {
    this.$emit(command);
  }

  saveHandler() {
    this.$emit('save');
  }
}
